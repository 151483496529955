import { EventEmitterService } from "./../../../services/eventEmitterService";
import { TrainerTableComponent } from "./../components/trainer-table/trainer-table.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "../../../components/value-label";
import { Employee, EmployeeService } from "../../../services/employee.service";
import { FormHelper } from "../../../helper/form.helper";
import { AddPersonBottomSheetComponent } from "../components/add-person-bottom-sheet/add-person-bottom-sheet.component";

@Component({
  selector: "trainer-page",
  templateUrl: "./trainer-page.component.html",
  styleUrls: ["./trainer-page.component.scss"],
})
export class TrainerPageComponent implements OnInit {
  @ViewChild("emptable", { static: true }) employeeTable: TrainerTableComponent;

  employees: Employee[];
  showAddEmployeeCard = false;
  selectedEmployee: Employee;
  roles: ValueLabel[] = [];

  constructor(private employeeService: EmployeeService, private translateService: TranslateService, private formHelper: FormHelper, private bottomSheet: MatBottomSheet, private eventEmitterService: EventEmitterService) {}

  ngOnInit() {
    this.eventEmitterService.listen().subscribe(() => {
      this.getUsers();
    });
    this.getUsers();
    this.roles.push({ label: this.translateService.instant("gib_trainer"), value: "gib_trainer" });
  }

  getUsers() {
    this.employeeService.findByRoles(["gib_trainer", "gib_afg"]).subscribe((res) => {
      this.selectedEmployee = null;
      this.employeeTable.resetDisplayedColumns();
      this.employees = res.body.sort(this.formHelper.sortByLastnameAndFirstname);
    });
  }

  setSelectedEmployee(emp: Employee) {
    this.selectedEmployee = emp;
    this.openBottomSheet();
  }

  openAddEmployeeCard() {
    this.selectedEmployee = new Employee();
    this.selectedEmployee.role = "gib_trainer";
    const sheetData = { selectedEmployee: this.selectedEmployee };
    this.bottomSheet.open(AddPersonBottomSheetComponent, { data: sheetData });
  }

  openBottomSheet() {
    this.employeeService.findOne(this.selectedEmployee.id).subscribe((res) => {
      const sheetData = { selectedEmployee: res.body };
      this.bottomSheet.open(AddPersonBottomSheetComponent, { data: sheetData });
    });
  }
}
