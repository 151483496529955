<mat-toolbar *ngIf="userRole === 'gib_employee' || userRole === 'gib_admin' || userRole === 'gib_director'">
  <mat-toolbar-row>
    <div class="profile-div">
      <i class="material-icons" id="loginButton" *ngIf="isLoggedIn" routerLink="/">home</i>
    </div>
    <span class="toolbar-spacer"></span>
    <div class="shopping-div" *ngIf="this.userRole === 'gib_customer'">
      <i [matMenuTriggerFor]="cartMenu" class="material-icons" matBadgeColor="primary" matBadgeSize="small" [matBadge]="cartItemCount()" matBadgePosition="above before" *ngIf="cartHasItems()">shopping_cart</i>
    </div>
    <mat-menu #cartMenu="matMenu">
      <button mat-menu-item (click)="bookModules()">{{ "bookModules" | translate }}</button>
      <button mat-menu-item (click)="clearCart()">{{ "clearModules" | translate }}</button>
    </mat-menu>
    <div>
      <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a>
    </div>
    <div class="profile-div">
      <span (click)="login()" *ngIf="!isLoggedIn">{{ "login" | translate }}</span>
      <i class="material-icons" id="profileIcon" *ngIf="isLoggedIn" [matMenuTriggerFor]="profileMenu">person</i>
    </div>
    <mat-menu #profileMenu="matMenu">
      <button mat-menu-item routerLink="/my-profile">{{ "myProfile" | translate }}</button>
      <button id="logout-button" mat-menu-item (click)="logout()">{{ "logout" | translate }}</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<!-- customer view -->
<div class="bkg-toolbar" *ngIf="isLoggedIn && userRole === 'gib_customer'">
  <div class="bg-polygon">
    <div class="center w-100 imgWrapper">
      <img src="assets/images/logo.svg" alt="Gesundheit in Bewegung" style="overflow: visible; width: 170px; max-width: 170px" />
    </div>
    <div class="row d-flex justify-content-between">
      <mat-icon class="menuIcons" (click)="openNav()">menu</mat-icon>

      <div class="d-flex">
        <div ngbDropdown #cart="ngbDropdown" container="body">
          <i class="material-icons cart-toggle" *ngIf="isLoggedIn && cartHasItems()" ngbDropdownToggle (mouseenter)="$event.stopPropagation(); cart.open()" matBadgeColor="primary" matBadgeSize="small" [matBadge]="cartItemCount()" matBadgePosition="above before">shopping_cart</i>
          <div ngbDropdownMenu (mouseleave)="$event.stopPropagation(); cart.close()">
            <div class="d-flex actions flex-column justify-content-between align-items-center">
              <button class="btn btn-outline" (click)="bookModules()">{{ "bookModules" | translate }}</button>
              <button class="btn btn-outline" (click)="clearCart()">{{ "clearModules" | translate }}</button>
            </div>
          </div>
        </div>

        <!-- <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a> -->

        <div class="profileMenu" ngbDropdown #profile="ngbDropdown" container="body">
          <mat-icon class="menuIcons" *ngIf="isLoggedIn" ngbDropdownToggle (mouseenter)="$event.stopPropagation(); profile.open()">person</mat-icon>
          <div style="width: 300px" class="p-2" ngbDropdownMenu (mouseleave)="$event.stopPropagation(); profile.close()">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="header">{{ "myProfile" | translate }}</h2>
              <mat-icon svgIcon="edit_profile" routerLink="/my-profile" class="material-icons"> </mat-icon>
            </div>
            <div class="d-flex justify-content-between">
              <p class="ml-2">{{ userEmail }}</p>
            </div>

            <div class="actions">
              <button (click)="logout()">{{ "logout" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="toolbarNav" class="overlay">
    <div class="row overlay-content">
      <div class="col-xl-12 col">
        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/faq" (click)="closeNav()">{{ "faqTitle" | translate }}</a>
          <a class="smallLink" routerLink="/privacy-page" (click)="closeNav()">{{ "attachmentPrivacy" | translate }}</a>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/customer-health-screening-overview-page/ACTIVE_BREAK" (click)="closeNav()">{{ "activeBreaks" | translate }}</a>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/customer-health-screening-overview-page/WEBINAR" (click)="closeNav()">{{ "interactiveWebinars" | translate }}</a>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/customer-health-screening-overview-page/ONLINE" (click)="closeNav()">{{ "onlineModules" | translate }}</a>
          <a target="_blank" href="https://www.gib-gesundheit.de/">
            <mat-icon svgIcon="website" class="material-icons mt-1">website</mat-icon>
          </a>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/customer-health-screening-overview-page/INDOOR_OUTDOOR" (click)="closeNav()">{{ "healthscreeningOverview" | translate }}</a>
          <a target="_blank" href="https://www.instagram.com/gibgmbh/">
            <mat-icon svgIcon="instagram" class="material-icons mt-1">instagram</mat-icon>
          </a>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/my-events" (click)="closeNav()">{{ "myEvents" | translate }}</a>
          <a target="_blank" href="https://www.xing.com/pages/gib-gesundheitinbewegunggmbh">
            <mat-icon svgIcon="xing" class="material-icons mt-1">xing</mat-icon>
          </a>
        </div>
        <a routerLink="/event-inquiry-page/FORM" (click)="closeNav()">{{ "createEventInquiry" | translate }}</a>

        <div class="d-flex justify-content-between align-items-center">
          <a routerLink="/" (click)="closeNav()">{{ "homepage" | translate }}</a>
        </div>
        <a class="closebtn" (click)="closeNav()">&times;</a>
      </div>
    </div>
  </div>
</div>

<!-- trainer view -->
<div class="bkg-toolbar" *ngIf="isLoggedIn && (userRole === 'gib_trainer' || userRole === 'gib_afg')">
  <div class="bg-polygon">
    <div class="center w-100 imgWrapper">
      <img src="assets/images/logo.svg" alt="Gesundheit in Bewegung" style="overflow: visible; width: 170px; max-width: 170px" />
    </div>
    <div class="row d-flex justify-content-between">
      <mat-icon class="menuIcons" (click)="openNav()">menu</mat-icon>

      <div class="d-flex">
        <div ngbDropdown #cart="ngbDropdown" container="body">
          <mat-icon class="menuIcons cart-toggle" *ngIf="isLoggedIn && cartHasItems()" ngbDropdownToggle (mouseenter)="$event.stopPropagation(); cart.open()" matBadgeColor="primary" matBadgeSize="small" [matBadge]="cartItemCount()" matBadgePosition="above before">shopping_cart</mat-icon>
          <div ngbDropdownMenu (mouseleave)="$event.stopPropagation(); cart.close()">
            <div class="d-flex actions flex-column justify-content-between align-items-center">
              <button class="btn btn-outline" (click)="bookModules()">{{ "bookModules" | translate }}</button>
              <button class="btn btn-outline" (click)="clearCart()">{{ "clearModules" | translate }}</button>
            </div>
          </div>
        </div>

        <!-- <a class="language-toggle" (click)="onLangClick()">{{ lang }}</a> -->

        <div class="profileMenu" ngbDropdown #profile="ngbDropdown" container="body">
          <mat-icon class="menuIcons" *ngIf="isLoggedIn" ngbDropdownToggle (mouseenter)="$event.stopPropagation(); profile.open()">person</mat-icon>
          <div style="width: 300px" class="p-2" ngbDropdownMenu (mouseleave)="$event.stopPropagation(); profile.close()">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="header">{{ "myProfile" | translate }}</h2>
              <mat-icon svgIcon="edit_profile" routerLink="/my-profile" class="material-icons"> </mat-icon>
            </div>
            <div class="d-flex justify-content-between">
              <p class="ml-2">{{ userEmail }}</p>
            </div>

            <div class="actions">
              <button (click)="logout()">{{ "logout" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="toolbarNav" class="overlay">
    <div class="row overlay-content">
      <div class="col-9">
        <a *ngIf="userRole !== 'gib_afg'" routerLink="/faq" (click)="closeNav()">{{ "faqTitle" | translate }}</a>
        <a *ngIf="userRole !== 'gib_afg'" routerLink="/customer-health-screening-overview-page/ONLINE" (click)="closeNav()">{{ "onlineModules" | translate }}</a>
        <a *ngIf="userRole !== 'gib_afg'" routerLink="/customer-health-screening-overview-page/INDOOR_OUTDOOR" (click)="closeNav()">{{ "healthscreeningOverview" | translate }}</a>
        <a routerLink="/trainer-calendar" (click)="closeNav()">{{ "calendar" | translate }}</a>
        <a routerLink="/my-tasks" (click)="closeNav()">{{ "myTasks" | translate }}</a>
        <a routerLink="/" (click)="closeNav()">{{ "homepage" | translate }}</a>
      </div>
      <div class="col-3">
        <a class="smallLink" routerLink="/privacy-page" (click)="closeNav()">{{ "attachmentPrivacy" | translate }}</a>
        <a target="_blank" href="https://www.gib-gesundheit.de/">
          <mat-icon svgIcon="website" class="material-icons mt-1">website</mat-icon>
        </a>
        <a target="_blank" href="https://www.instagram.com/gibgmbh/">
          <mat-icon svgIcon="instagram" class="material-icons mt-1">instagram</mat-icon>
        </a>
        <a target="_blank" href="https://www.xing.com/pages/gib-gesundheitinbewegunggmbh">
          <mat-icon svgIcon="xing" class="material-icons mt-1">xing</mat-icon>
        </a>
        <a class="closebtn" (click)="closeNav()">&times;</a>
      </div>
    </div>
  </div>
</div>
