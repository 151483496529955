<div class="card">
  <div class="card-body">
    <form [formGroup]="eventInquiryHealthscreening">
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12 col-md-1"></div>
          <div class="col-sm-12 col-md-2">
            <gib-datepicker [placeholder]="'eventDate'" [control]="eventForm.get('eventDate')"></gib-datepicker>
          </div>
          <div class="col-sm-12 col-md-8"></div>
          <div class="col-sm-12 col-md-1">
            <gib-module-replace *ngIf="allowModuleReplace" [editMode]="editMode" [eventInquiryHealthscreening]="eventInquiryHealthscreening" [modulesControl]="eventForm.get('eventHealthScreenings')" [customer]="eventForm.get('customer')"></gib-module-replace>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-1"></div>
          <div class="col-sm-12 col-md-2">
            <gib-timepicker [placeholder]="'from'" [control]="eventInquiryHealthscreening.get('timeFrom')"></gib-timepicker>
          </div>
          <div class="col-sm-12 col-md-2">
            <gib-timepicker [placeholder]="'to'" [control]="eventInquiryHealthscreening.get('timeTo')"></gib-timepicker>
          </div>
          <div class="col-sm-12 col-md-2">
            <gib-input *ngIf="moduleType === 'HEALTH_MODULE'" [placeholder]="'frequency'" [control]="eventInquiryHealthscreening.get('timing')"></gib-input>
            <gib-input *ngIf="moduleType !== 'HEALTH_MODULE'" [placeholder]="'maxParticipants'" [control]="eventInquiryHealthscreening.get('maxParticipants')"></gib-input>
          </div>
          <div class="col-sm-12 col-md-2 mb-3">
            <button type="button" class="btn btn-primary" *ngIf="userRole === 'gib_admin' || userRole === 'gib_director' || userRole === 'gib_employee' || userRole === 'gib_trainer'" (click)="createEventParticipants()" [disabled]="!isCreateButtonEnabled()">
              {{ "createList" | translate }}
            </button>
          </div>
          <div class="col-sm-12 col-md-3">
            <gib-input *ngIf="userRole === 'gib_admin' || userRole === 'gib_director' || userRole === 'gib_employee'" [placeholder]="'moduleNameInvoce'" [control]="eventInquiryHealthscreening.get('healthscreeningInvoicename')"></gib-input>
          </div>
        </div>
        <div *ngIf="userRole === 'gib_admin' || userRole === 'gib_director' || userRole === 'gib_employee' || userRole === 'gib_trainer'">
          <div class="row">
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-2">
              <gib-radio-button-group-vertical-v2 label="" [control]="eventInquiryHealthscreening.get('onlineModule')" [valueLabels]="onlineModuleOptions"></gib-radio-button-group-vertical-v2>
            </div>
            <div class="col-sm-12 col-md-6">
              <gib-radio-button-group-vertical-v2 label="" [control]="eventInquiryHealthscreening.get('presentationEnglish')" [valueLabels]="presentationEnglishOptions"></gib-radio-button-group-vertical-v2>
            </div>
            <div class="col-sm-12 col-md-3">
              <gib-input *ngIf="userRole === 'gib_admin' || userRole === 'gib_director' || userRole === 'gib_employee'" [placeholder]="'moduleNameEn'" [control]="eventInquiryHealthscreening.get('nameEn')"></gib-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-2" style="margin-top: 27px"><gib-checkbox [label]="'PRESENTATION'" [control]="eventInquiryHealthscreening.get('presentation')"></gib-checkbox></div>
            <div class="col-sm-12 col-md-2" style="margin-top: 27px">
              <gib-checkbox [label]="'presentationBillOnly'" [control]="eventInquiryHealthscreening.get('presentationBillOnly')"></gib-checkbox>
            </div>
            <div class="col-sm-12 col-md-2">
              <gib-timepicker *ngIf="eventInquiryHealthscreening.get('presentation').value" [placeholder]="'from'" [control]="eventInquiryHealthscreening.get('presentationTimeFrom')"></gib-timepicker>
            </div>
            <div class="col-sm-12 col-md-2">
              <gib-timepicker *ngIf="eventInquiryHealthscreening.get('presentation').value" [placeholder]="'to'" [control]="eventInquiryHealthscreening.get('presentationTimeTo')"></gib-timepicker>
            </div>
            <div class="col-sm-12 col-md-3"></div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-3"><gib-checkbox [label]="'pdfScheduleLabel'" [control]="eventInquiryHealthscreening.get('pdfSchedule')"></gib-checkbox></div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-2"><gib-checkbox (checkboxChecked)="unselectCheckbox($event, 'hybrid')" [label]="'onlineScheduleLabel'" [control]="eventInquiryHealthscreening.get('onlineSchedule')"></gib-checkbox></div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-3"><gib-checkbox [label]="'registrationViaLandingPage'" [control]="eventInquiryHealthscreening.get('registrationViaLandingPage')"></gib-checkbox></div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-3">
              <gib-checkbox *ngIf="moduleType === 'HEALTH_MODULE'" [label]="'moduleIntroduction'" [control]="eventInquiryHealthscreening.get('shortPresentation')"></gib-checkbox>
            </div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-4"><gib-checkbox [label]="'scheduleEntryCompanynameRequired'" [control]="eventInquiryHealthscreening.get('scheduleEntryCompanynameRequired')"></gib-checkbox></div>
            <div class="col-sm-12 col-md-3"><gib-checkbox [label]="'showWaitingList'" [control]="eventInquiryHealthscreening.get('showWaitingList')"></gib-checkbox></div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-3">
              <gib-checkbox *ngIf="moduleType === 'HEALTH_MODULE'" [label]="'anonymusEvaluation'" [control]="eventInquiryHealthscreening.get('anonymousEvaluation')"></gib-checkbox>
            </div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-3">
              <gib-checkbox [label]="'attachmentPrivacy'" [control]="eventInquiryHealthscreening.get('attachmentPrivacy')"></gib-checkbox>
            </div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-3"><gib-checkbox (checkboxChecked)="unselectCheckbox($event, 'onlineSchedule')" [label]="'hybrid'" [control]="eventInquiryHealthscreening.get('hybrid')"></gib-checkbox></div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-2">
              <gib-checkbox *ngIf="moduleType === 'HEALTH_MODULE'" [label]="'anonymusEvaluationBillOnly'" [control]="eventInquiryHealthscreening.get('anonymousEvaluationBillOnly')"></gib-checkbox>
            </div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-2">
              <gib-checkbox [label]="'skipSchedule'" [control]="eventInquiryHealthscreening.get('skipSchedule')"></gib-checkbox>
            </div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-1"></div>
          </div>
        </div>

        <gib-fieldset *ngIf="moduleType === 'HEALTH_MODULE'" [title]="'breaks'" [disabled]="eventInquiryHealthscreening.get('breakTime1From').disabled">
          <div class="row">
            <div class="col-sm-4 label-col">
              <mat-label [ngStyle]="{ color: eventInquiryHealthscreening.get('breakTime1From').disabled ? 'var(--hover)' : 'inherit' }">{{ "break1" | translate }}</mat-label>
            </div>
            <div class="col-sm-2">
              <gib-timepicker [placeholder]="'from'" [control]="eventInquiryHealthscreening.get('breakTime1From')" [minutesGap]="breakTimeMinutesGap"></gib-timepicker>
            </div>
            <div class="col-sm-2">
              <gib-timepicker [placeholder]="'to'" [control]="eventInquiryHealthscreening.get('breakTime1To')" [minutesGap]="breakTimeMinutesGap"></gib-timepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 label-col">
              <mat-label [ngStyle]="{ color: eventInquiryHealthscreening.get('breakTime1From').disabled ? 'var(--hover)' : 'inherit' }">{{ "break2" | translate }}</mat-label>
            </div>
            <div class="col-sm-2">
              <gib-timepicker [placeholder]="'from'" [control]="eventInquiryHealthscreening.get('breakTime2From')" [minutesGap]="breakTimeMinutesGap"></gib-timepicker>
            </div>
            <div class="col-sm-2">
              <gib-timepicker [placeholder]="'to'" [control]="eventInquiryHealthscreening.get('breakTime2To')" [minutesGap]="breakTimeMinutesGap"></gib-timepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 label-col">
              <mat-label [ngStyle]="{ color: eventInquiryHealthscreening.get('breakTime1From').disabled ? 'var(--hover)' : 'inherit' }">{{ "break3" | translate }}</mat-label>
            </div>
            <div class="col-sm-2">
              <gib-timepicker [placeholder]="'from'" [control]="eventInquiryHealthscreening.get('breakTime3From')" [minutesGap]="breakTimeMinutesGap"></gib-timepicker>
            </div>
            <div class="col-sm-2">
              <gib-timepicker [placeholder]="'to'" [control]="eventInquiryHealthscreening.get('breakTime3To')" [minutesGap]="breakTimeMinutesGap"></gib-timepicker>
            </div>
          </div>
        </gib-fieldset>

        <gib-fieldset [title]="'TRAINER'" [disabled]="eventInquiryHealthscreening.get('breakTime1From').disabled" *ngIf="eventInquiryHealthscreening.get('trainer').get('0')">
          <div class="row">
            <div class="col-sm-4">
              <gib-input [placeholder]="'firstname'" [control]="eventInquiryHealthscreening.get('trainer').get('0').get('firstname')"></gib-input>
            </div>
            <div class="col-sm-4">
              <gib-input [placeholder]="'lastname'" [control]="eventInquiryHealthscreening.get('trainer').get('0').get('lastname')"></gib-input>
            </div>
            <div class="col-sm-4">
              <gib-input [placeholder]="'phonenumber'" [control]="eventInquiryHealthscreening.get('trainer').get('0').get('phonenumber')"></gib-input>
            </div>
          </div>
        </gib-fieldset>

        <div class="row" *ngIf="userRole === 'gib_admin' || userRole === 'gib_director' || userRole === 'gib_employee' || userRole === 'gib_trainer'">
          <div class="col-sm-12">
            <gib-fieldset *ngIf="eventInquiryHealthscreening.get('scheduleEntries').value && eventInquiryHealthscreening.get('scheduleEntries').value.length > 0" [title]="'participantList'" [disabled]="eventInquiryHealthscreening.get('breakTime1From').disabled">
              <div class="row">
                <div class="col-sm-12">
                  <i *ngIf="isEditable && !url.includes('event-tasks')" class="material-icons actionButton" (click)="copyScheduleListLink()" placement="left" [ngbTooltip]="'copyLinkForParticipants' | translate">library_books</i>
                  <i *ngIf="eventInquiryHealthscreening.get('breakTime1From').disabled" class="material-icons actionButton" (click)="openDownloadExcelConfirmation()" placement="left" [ngbTooltip]="'downLoadParticipantsTooltip' | translate">get_app</i>
                </div>
              </div>
              <div *ngIf="isEditable && eventInquiryHealthscreening.get('breakTime1From').disabled" class="row">
                <div class="line"><mat-divider></mat-divider></div>
                <div class="addslot mat-typography" (click)="addSlotBefore()">{{ "ADD_SLOT" | translate }}</div>
                <div class="line"><mat-divider></mat-divider></div>
              </div>
              <event-participant
                *ngFor="let participant of eventInquiryHealthscreening.get('scheduleEntries').controls"
                [sendAppointmentlistToCorporateCustomer]="eventForm.get('sendAppointmentlistToCorporateCustomer').value"
                [eventParticipant]="participant"
                [eventInquiryHealthscreening]="eventInquiryHealthscreening"
              ></event-participant>
              <div *ngIf="isEditable && eventInquiryHealthscreening.get('breakTime1From').disabled" class="row">
                <div class="line"><mat-divider></mat-divider></div>
                <div class="addslot mat-typography" (click)="addSlotAfter()">{{ "ADD_SLOT" | translate }}</div>
                <div class="line"><mat-divider></mat-divider></div>
              </div>
            </gib-fieldset>
          </div>
        </div>
        <div class="row" *ngIf="userRole === 'gib_admin' || userRole === 'gib_director' || userRole === 'gib_employee'">
          <div class="col-sm-12">
            <gib-fieldset *ngIf="eventInquiryHealthscreening.get('waitingList').value && eventInquiryHealthscreening.get('waitingList').value.length > 0" [title]="'waitinglist'" [disabled]="true">
              <div class="row">
                <div class="col-sm-12">
                  <i *ngIf="eventInquiryHealthscreening.get('breakTime1From').disabled" class="material-icons actionButton" (click)="openDownloadWaitingListExcelConfirmation()" placement="left" [ngbTooltip]="'downloadWaitingListTooltip' | translate">get_app</i>
                </div>
              </div>
              <event-waitlist *ngFor="let waitinglistEntry of eventInquiryHealthscreening.get('waitingList').controls" [waitingListEntry]="waitinglistEntry" [eventInquiryHealthscreening]="eventInquiryHealthscreening"></event-waitlist>
            </gib-fieldset>
          </div>
        </div>
        <div class="row" *ngIf="userRole === 'gib_customer'">
          <div class="col-sm-12">
            <gib-fieldset *ngIf="eventInquiryHealthscreening.get('scheduleEntries').value && eventInquiryHealthscreening.get('scheduleEntries').value.length > 0" [title]="'participantList'" [disabled]="eventInquiryHealthscreening.get('breakTime1From').disabled">
              <event-participant *ngFor="let participant of eventInquiryHealthscreening.get('scheduleEntries').controls" [sendAppointmentlistToCorporateCustomer]="eventForm.get('sendAppointmentlistToCorporateCustomer').value" [eventParticipant]="participant"></event-participant>
            </gib-fieldset>
          </div>
        </div>
        <hr />
        <div *ngIf="userRole !== 'gib_customer'">
          <gib-textarea [placeholder]="'commentsAdditions'" [control]="eventForm.get('commentsAdditions')"></gib-textarea>
          <gib-input [placeholder]="'urlForTrainer'" [control]="eventInquiryHealthscreening.get('urlForTrainer')"></gib-input>
        </div>
      </div>
    </form>
  </div>
</div>
