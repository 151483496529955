import { Injectable } from "@angular/core";
import { UntypedFormControl, AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PhoneNumberUtil } from "google-libphonenumber";
import { GibDialogService } from "../components/dialogs/gib-dialog.service";
import { AddressType, Address } from "../services/keycloak-connector.service";
import { Customer, CustomerService } from "../services/customer.service";
import * as moment from "moment";
import { SelectOptions } from "../utils/select-options";

@Injectable()
export class FormHelper {
  phoneUtil;

  scrollPositions = {};

  constructor(private translateService: TranslateService, private dialogService: GibDialogService, private formBuilder: UntypedFormBuilder, private customerService: CustomerService) {
    this.phoneUtil = PhoneNumberUtil.getInstance();
  }

  createErrorMessage(formGroup): Array<string> {
    let errorMessages = [];
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        errorMessages = errorMessages.concat(this.createErrorMessage(formGroup.get(key)));
      }
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          switch (keyError) {
            case "required":
              if (key === "gdprChecked") {
                errorMessages.push(this.translateService.instant("privacyCheckErrorMessage"));
              } else {
                errorMessages.push(this.createRequiredErrorMessageFromTranslationKey(key));
              }
              break;
            case "email":
              errorMessages.push(this.translateService.instant("email_invalid"));
              break;
            case "noMobileNumber":
              errorMessages.push(this.translateService.instant("no_mobile_number"));
              break;
            case "pwNotSame":
              errorMessages.push(this.translateService.instant("pwsNotSame"));
              break;
            case "noDrivingLicenseImg":
              errorMessages.push(this.translateService.instant("noDrivingLicenseImg"));
              break;
            case "noValidDate":
              errorMessages.push(this.createNoValidDateFormatTranslation(key));
              break;
            case "noValidNumber":
              errorMessages.push(this.createNoValidNumberErrorMessageFromTranslationKey(key));
              break;
          }
        });
      }
    });

    return errorMessages;
  }

  hasOnlyOverwritableErrors(formGroup, statusBefore?: boolean) {
    let onlyOverwritableErrors = typeof statusBefore !== "undefined" ? statusBefore : true;

    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;

      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        onlyOverwritableErrors = this.hasOnlyOverwritableErrors(formGroup.get(key), onlyOverwritableErrors);
      }

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          switch (keyError) {
            case "required":
              onlyOverwritableErrors = false;
              break;
            case "email":
              onlyOverwritableErrors = false;
              break;
            case "pwNotSame":
              onlyOverwritableErrors = false;
              break;
            case "noDrivingLicenseImg":
              onlyOverwritableErrors = false;
              break;
          }
        });
      }
    });
    return onlyOverwritableErrors;
  }

  checkRequiredValidator(control: UntypedFormControl) {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      } else {
        return false;
      }
    }
  }

  createRequiredErrorMessage(fieldName: string) {
    return this.translateService.instant("IS_REQUIRED_FIELD", { fieldName });
  }

  createRequiredErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("IS_REQUIRED_FIELD", { fieldName: this.translateService.instant(translationKey) });
  }

  createNoValidDateFormatTranslation(translationKey: string) {
    return this.translateService.instant("noValidDate", { dateField: this.translateService.instant(translationKey) });
  }

  createNoValidNumberErrorMessage(fieldName: string) {
    return this.translateService.instant("noValidNumber", { fieldName });
  }

  createNoValidNumberErrorMessageFromTranslationKey(translationKey: string) {
    return this.translateService.instant("noValidNumber", { fieldName: this.translateService.instant(translationKey) });
  }

  createFieldErrorMessage(formGroup): Array<string> {
    let errorMessages = [];
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        errorMessages = errorMessages.concat(this.createFieldErrorMessage(formGroup.get(key)));
      }
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          switch (keyError) {
            case "required":
              if (key === "gdprChecked") {
                errorMessages.push(this.translateService.instant("privacyCheckErrorMessage"));
              } else {
                errorMessages.push(this.createRequiredErrorMessageFromTranslationKey(key));
              }
              break;
            case "email":
              errorMessages.push(this.translateService.instant("email_invalid"));
              break;
            case "noMobileNumber":
              errorMessages.push(this.translateService.instant("no_mobile_number"));
              break;
            case "pwNotSame":
              errorMessages.push(this.translateService.instant("pwsNotSame"));
              break;
            case "noDrivingLicenseImg":
              errorMessages.push(this.translateService.instant("noDrivingLicenseImg"));
              break;
            case "noValidDate":
              errorMessages.push(this.createNoValidDateFormatTranslation(key));
              break;
            case "noValidNumber":
              errorMessages.push(this.createNoValidNumberErrorMessageFromTranslationKey(key));
              break;
          }
        });
      }
    });

    return errorMessages;
  }

  createFormErrorMessage(formGroup): Array<string> {
    let errorMessages = [];
    if (formGroup.errors) {
      Object.keys(formGroup.errors).forEach((keyError) => {
        switch (keyError) {
          case "requireCheckboxToBeChecked": {
            const { error, fields }: { error: boolean; fields: string[] } = formGroup.errors[keyError];
            if (error === true) {
              if (fields && fields.length > 0) {
                let message: string = "";
                fields.forEach((field) => {
                  message = message + this.translateService.instant(field) + " and/or ";
                });
                message = message.substring(0, message.lastIndexOf(" and/or "));
                message = message + " " + this.translateService.instant("IS_REQUIRED");
                errorMessages.push(message);
              }
            }
            break;
          }
          case "invalidItems": {
            errorMessages.push(this.translateService.instant("INVALID_MODULES"));
            break;
          }
        }
      });
    }
    return errorMessages;
  }

  isMobileNumber(): ValidatorFn {
    return (
      control: AbstractControl
    ): {
      [key: string]: any;
    } => {
      let validNumber = false;
      try {
        const phoneNumber = this.phoneUtil.parseAndKeepRawInput(control.value, "DE");
        // getNumberType: 0 for fixed line | 1 for mobile
        validNumber = this.phoneUtil.getNumberType(phoneNumber) === 1;
      } catch (e) {
        // required is handled separately
        control.value === "" ? (validNumber = true) : (validNumber = false);
      }

      return validNumber ? null : { noMobileNumber: { value: control.value } };
    };
  }

  isDateValid(): ValidatorFn {
    return (
      control: AbstractControl
    ): {
      [key: string]: any;
    } => {
      let validDate = true;
      if (control.value && control.value !== "") {
        validDate = moment(control.value, SelectOptions.dateFormats.display.dateInput, true).isValid();
      }
      return validDate ? null : { noValidDate: { value: control.value } };
    };
  }

  isValidNumber(): ValidatorFn {
    return (
      control: AbstractControl
    ): {
      [key: string]: any;
    } => {
      let validNumber = true;
      if (control.value) {
        validNumber = !isNaN(control.value);
      }
      return validNumber ? null : { noValidNumber: { value: control.value } };
    };
  }

  trimWhitespace(formControl: UntypedFormControl) {
    if (formControl.value) {
      formControl.setValue(formControl.value.trim());
    }
  }

  checkPasswords(formGroup: UntypedFormGroup) {
    const pass = formGroup.controls.password.value;
    const passwordConfirmation = formGroup.controls.passwordConfirmation.value;

    if (pass && pass !== "" && pass !== passwordConfirmation) {
      formGroup.controls.passwordConfirmation.setErrors({ pwNotSame: true });
      formGroup.controls.passwordConfirmation.markAsTouched();
    } else {
      formGroup.controls.passwordConfirmation.setErrors(null);
      formGroup.controls.passwordConfirmation.markAsUntouched();
    }
    return null;
  }

  disableControls(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).disable({ emitEvent: false });
    });
  }

  disableControlsByName(formGroup: AbstractControl, controlNames: string[]) {
    for (const controlName of controlNames) {
      formGroup.get(controlName).disable({ emitEvent: false });
    }
  }

  enableControls(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).enable({ emitEvent: false });
    });
  }

  enableControlsByName(formGroup: AbstractControl, controlNames: string[]) {
    for (const controlName of controlNames) {
      formGroup.get(controlName).enable({ emitEvent: false });
    }
  }

  isFormValidElseShowErrors(form: UntypedFormGroup, title: string, confirmAction: any) {
    if (!form.valid) {
      this.markControlsAsTouched(form);
      let fieldErrorMessages = this.createFieldErrorMessage(form);
      const formErrorMessages = this.createFormErrorMessage(form);
      if (!fieldErrorMessages) {
        fieldErrorMessages = [];
      }
      const errorMessages = fieldErrorMessages.concat(formErrorMessages);
      if (this.hasOnlyOverwritableFieldErrors(form) && this.hasOnlyOverwritableFormErrors(form)) {
        this.dialogService.openConfirmationErrorDialog(title, errorMessages, confirmAction);
      } else {
        this.dialogService.openErrorDialog(title, errorMessages);
      }
    } else {
      confirmAction();
    }
  }

  hasOnlyOverwritableFieldErrors(formGroup, statusBefore?: boolean) {
    let onlyOverwritableErrors = typeof statusBefore !== "undefined" ? statusBefore : true;
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        onlyOverwritableErrors = this.hasOnlyOverwritableFieldErrors(formGroup.get(key), onlyOverwritableErrors);
      }
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          switch (keyError) {
            case "required":
              onlyOverwritableErrors = false;
              break;
            case "email":
              onlyOverwritableErrors = false;
              break;
            case "pwNotSame":
              onlyOverwritableErrors = false;
              break;
            case "noDrivingLicenseImg":
              onlyOverwritableErrors = false;
              break;
          }
        });
      }
    });
    return onlyOverwritableErrors;
  }

  hasOnlyOverwritableFormErrors(formGroup) {
    let onlyOverwritableErrors = true;
    if (formGroup.errors) {
      Object.keys(formGroup.errors).forEach((keyError) => {
        switch (keyError) {
          case "requireCheckboxToBeChecked": {
            onlyOverwritableErrors = false;
            break;
          }
          case "invalidItems": {
            onlyOverwritableErrors = false;
            break;
          }
        }
      });
    }
    return onlyOverwritableErrors;
  }

  markControlsAsTouched(formGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).markAsTouched();
      // if controls exists, it's a formGroup with nested form controls in it --> so start recursion
      if (formGroup.get(key).controls) {
        this.markControlsAsTouched(formGroup.get(key));
      }
    });
  }

  sortByLastname(a, b) {
    if (a.lastname < b.lastname) {
      return -1;
    }
    if (a.lastname > b.lastname) {
      return 1;
    }
    return 0;
  }

  sortByLabel(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  sortByModuleName(a, b) {
    if (a.moduleName < b.moduleName) {
      return -1;
    }
    if (a.moduleName > b.moduleName) {
      return 1;
    }
    return 0;
  }

  sortByCompanyName(a, b) {
    if (a.companyname < b.companyname) {
      return -1;
    }
    if (a.companyname > b.companyname) {
      return 1;
    }
    return 0;
  }

  sortTasksByCreatedDate(a, b) {
    const dateA = moment(a.task.created).toDate().getTime();
    const dateB = moment(b.task.created).toDate().getTime();
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  sortTasksByEventDate(a, b) {
    const dateA = moment(a.eventDate).toDate().getTime();
    const dateB = moment(b.eventDate).toDate().getTime();
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  sortByProperty(propertyName: string) {
    return (a, b) => {
      if (a[propertyName] && typeof a[propertyName] === "string" && b[propertyName] && typeof b[propertyName] === "string") {
        return a[propertyName].toLowerCase().localeCompare(b[propertyName].toLowerCase());
      }

      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    };
  }

  sortByDateProperty(propertyName) {
    return (a, b) => {
      const dateA = this.getTime(moment(a[propertyName]).toDate().getTime());
      const dateB = this.getTime(moment(b[propertyName]).toDate().getTime());
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    };
  }

  getTime(time: number) {
    return time ? time : 0;
  }

  sortTasksByProperty(propertyName: string) {
    return (a, b) => {
      if (a.task[propertyName] && typeof a.task[propertyName] === "string" && b.task[propertyName] && typeof b.task[propertyName] === "string") {
        return a.task[propertyName].toLowerCase().localeCompare(b.task[propertyName].toLowerCase());
      }

      if (a.task[propertyName] < b.task[propertyName]) {
        return -1;
      }
      if (a.task[propertyName] > b.task[propertyName]) {
        return 1;
      }
      return 0;
    };
  }

  sortByLastnameAndFirstname(a, b) {
    const lastnameA = a.lastname.toLowerCase();
    const lastnameB = b.lastname.toLowerCase();
    const firstnameA = a.firstname.toLowerCase();
    const firstnameB = b.firstname.toLowerCase();

    if (lastnameA === lastnameB) {
      return firstnameB - firstnameA;
    }
    return lastnameA > lastnameB ? 1 : -1;
  }

  handleAddressForForm(form: UntypedFormGroup, addressKey: string, addressType: AddressType, address: Address) {
    form.removeControl(addressKey);
    if (!address) {
      address = new Address();
      address.type = addressType;
    }
    form.addControl(addressKey, this.formBuilder.group(address));
  }

  handleCustomerForForm(form: UntypedFormGroup, customerKey: string, customer: Customer) {
    form.removeControl(customerKey);
    if (!customer) {
      customer = new Customer();
    }
    const customerForForm = this.customerService.mapCustomerToForm(customer);
    form.addControl(customerKey, customerForForm);
  }

  saveScrollPosition(page: string, position: number) {
    this.scrollPositions[page] = position;
  }

  getScrollPosition(page: string) {
    if (this.scrollPositions[page]) {
      return this.scrollPositions[page];
    } else {
      return 0;
    }
  }

  calculateDateDiff(data) {
    let date = new Date(data);
    let currentDate = new Date();
    date.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
}
