<h1>{{'EMPLOYEES' | translate}}</h1>
<div class="row">
  <div class="{{selectedEmployee ? 'col-md-12 col-lg-6' : 'col-md-12 col-lg-12'}}">
    <employees-table #emptable [employees]="employees" (employeeSelected)="setSelectedEmployee($event)" (addEmployeeClicked)="openAddEmployeeCard()" (employeeDeleted)="getUsers()"></employees-table>
  </div>
  <div *ngIf="selectedEmployee" class="col-md-12 col-lg-6 employee-details">
    <div class="sticky-top" style="top: 50px">
      <add-employee [employee]="selectedEmployee" [roles]="roles" (employeeAdded)="getUsers()"></add-employee>
    </div>
  </div>
</div>
