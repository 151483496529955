import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormHelper } from 'src/app/helper/form.helper';
import { EventService } from 'src/app/services/event.service';
import { ScheduleService } from 'src/app/services/schedule-vote.service';

@Component({
  selector: 'participants-list-page',
  templateUrl: './participants-list-page.component.html',
  styleUrls: ['./participants-list-page.component.scss']
})
export class ParticipantsListPageComponent implements OnInit {
  scheduleId;
  successful: boolean = false;
  eventParticipants: UntypedFormGroup[] = [];
  codeForm: UntypedFormGroup = new FormGroup({
    code: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    private scheduleService: ScheduleService,
    private route: ActivatedRoute,
    private formHelper: FormHelper,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.scheduleId = paramMap.get('id')
    });
  }

  getScheduleEntries() {
    this.scheduleService.getAfgScheduleEntries(this.scheduleId, this.codeForm.get('code').value).subscribe(res => {
      this.successful = true
      for (let participant of res.body) {
        let form = this.formBuilder.group(participant)
        this.formHelper.disableControls(form)
        this.eventParticipants.push(form)
      }
      this.cdr.detectChanges()
    }, (error) => {
        this.successful = false
      })
  }

}


