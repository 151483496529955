<mat-form-field>
  <mat-select placeholder="{{ placeholder }} " [formControl]="inputControl" (selectionChange)="emitChangedSelection($event)" multiple>
    <mat-select-trigger *ngIf="displayLabelAlways">
      {{ 'filter' | translate }}
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.label | translate }}
    </mat-option>
  </mat-select>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error *ngIf="inputControl.hasError('required')">{{ requiredErrorMessage }}</mat-error>
</mat-form-field>
